<template>
    <div class="full-height-layout fill px-3">
        <div class="row">
            <template v-for="(page, key) in pages">
                <div :key="key" v-if="(!page.onlyForVirtualSupplier || !isSupplier) && isAllowedTo(page.actionName)" class="col-sm-12 col-md-6 col-lg-4 full-height-layout">
                    <mercur-card class="fill full-height-layout">
                        <h2 class="font-weight-normal">{{page.title}}</h2>
                        <p>{{page.description}}</p>
                        <div class="text-right mt-3">
                            <mercur-button v-bind="page.attr" class="btn btn-raised text-uppercase" :to="page.route">go to {{page.title}}</mercur-button>
                        </div>
                    </mercur-card>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
export default {
    name: 'AgreementsManagementView',
    data () {
        return {
            pages: [
                {
                    title: 'Agreements',
                    description: `To manage Agreements and their configuration.`,
                    route: {
                        name: 'AgreementsView',
                    },
                    actionName: 'getAllAgreements',
                },
                /* {
                    title: 'Custom Carrier Cost',
                    description: `Section to manage custom carrier costs`,
                    route: {
                        name: 'CustomCarrierCostsOverview',
                    },
                    actionName: 'getCustomCarrierCosts',
                    onlyForVirtualSupplier: true,
                }, */
                {
                    title: 'Pricing',
                    description: `Section to manage pricing`,
                    route: {
                        name: 'PricingView',
                    },
                    attr: {
                        'data-test': 'agreementsViewPricingLink',
                    },
                    onlyForVirtualSupplier: true,
                },
            ],
        }
    },
}
</script>
